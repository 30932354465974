<template>
  <div class="message-wrap" :class="baloonStyle">
    <div class="display-flex">
      <div class="message-balloon" :class="baloonStyle">
        <div v-if="message.attachments">
          <ion-row
            v-for="(attachment, index) in message.attachments"
            :key="index"
            class="ion-align-items-center"
          >
            <ion-col>
              <span
                :class="{ 'cursor-pointer': attachment.url }"
                @click.stop="showImage(attachment)"
              >
                <div class="chat-image valign">
                  <IconEyeHidden v-if="attachment.hidden" class="icon-image-sensitive" />
                  <img
                    class="patient-image"
                    @error="setBrokenImg"
                    :class="{ 'img-is-hidden': attachment.hidden }"
                    :src="attachment.url ? attachment.url : ''"
                    alt="patient image"
                  />
                </div>
              </span>
            </ion-col>
            <ion-col v-if="attachment.url" size="auto">
              <button
                @click="showActions($event, attachment, message.id)"
                class="prime-button button-clear button-secondary muted"
              >
                <ion-icon name="md-more"></ion-icon>
              </button>
            </ion-col>
          </ion-row>
        </div>
        <div>
          <ChatMessage :line="message.body ? message.body : ''"></ChatMessage>
          <ChatInfo
            v-if="orientation === 'full' && messageDirection !== 'none'"
            :message="message"
            :direction="messageDirection"
            :timestamp="timestamp"
            :actions="actions"
            class="chat-info"
          />
        </div>
      </div>
      <div>
      <FailedMessageIcon
        v-if="message.status === 'failed'"
        class="icon-small center-center icon-image-failed"
        style="padding-left:5px"
      />
      </div>
  </div>
  <ChatInfo
    v-if="orientation !== 'full' && messageDirection !== 'none'"
    :message="message"
    :direction="messageDirection"
    :timestamp="timestamp"
    :actions="actions"
    class="chat-info"
  />
  </div>
</template>
<script>
import BrokenImg from "@/assets/Broken-Image-Full.png";
import ChatInfo from "@/components/Chat/ChatInfo";
import ChatMessage from "@/components/Chat/ChatMessage";
import ModalImage from "@/components/Chat/ModalImage";
import IconEyeHidden from "@/components/Global/Icons/IconEyeHidden";
import FailedMessageIcon from "@/components/Global/Icons/IconFailedMessage.vue";
import PopoverImageOptions from "@/components/Roster/PopoverImageOptions.vue";

export default {
  name: "ChatBubble",
  components: {
    ChatMessage,
    ChatInfo,
    IconEyeHidden,
    FailedMessageIcon
  },
  props: {
    actions: Object,
    message: Object,
    orientation: String,
    color: String,
    timestamp: String
  },
  computed: {
    baloonStyle() {
      let color = this.color;
      let orientation = this.orientation;
      if (this.messageDirection === "incoming") {
        color = "white";
        orientation = "left";
      } else if (this.messageDirection === "outgoing") {
        if (this.messageStatus === "failed") {
          color = "grey";
        } else {
          color = "blue";
        }
        orientation = "right";
      } else if (this.messageDirection === "none") {
        color = "system";
        orientation = "center";
      }
      return [color, orientation];
    },
    messageDirection() {
      var msgDirection = this.message.direction;
      if (this.isPatient) {
        if (msgDirection === "incoming") {
          msgDirection = "outgoing";
        } else if (msgDirection === "outgoing") {
          msgDirection = "incoming";
        }
      }
      return msgDirection;
    },
    messageStatus() {
      return this.message.status;
    }
  },
  methods: {
    setBrokenImg(event) {
      event.target.src = BrokenImg;
    },
    showImage(attachment) {
      if (attachment.url) {
        this.$ionic.modalController
          .create({
            component: ModalImage,
            mode: "ios",
            cssClass: "prime-modal-fullscreen darkmode",
            componentProps: {
              propsData: {
                id: attachment.id,
                url: attachment.url,
                isHidden: attachment.hidden,
                image: attachment
              }
            }
          })
          .then(m => {
            m.present();
          });
      }
    },
    showActions(ev, image) {
      this.$ionic.popoverController
        .create({
          component: PopoverImageOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: { image }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>

.message-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message-wrap.left {
  padding-right: 20%;
  align-items: flex-start;
}

.message-wrap.right {
  padding-left: 20%;
  align-items: flex-end;
}

.message-wrap.center {
  text-align: center;
}

.message-wrap.none {
  border-bottom: 2px solid var(--ion-background-color);
}

.message-balloon {
  font-size: 14px;
  font-weight: 500;
  max-width: 100%;
  padding: 15px;
  border-radius: 20px;
  flex: auto;
}

.message-balloon.grey {
  background-color: #aeaeae !important;
  color: #ffffff !important;
}

.message-balloon.left {
  border-bottom-left-radius: 0;
}

.message-balloon.white {
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
}

.message-balloon.white a {
  color: var(--ion-color-primary);
}

.message-balloon.system {
  background-color: var(--color-secondary-background);
  border-radius: 20px;
  border: 1px solid #d5d6d8;
  color: #939393;
  font-size: 11px;
}

.message-balloon.right {
  border-bottom-right-radius: 0;
}

.message-balloon.blue,
.message-balloon.blue a {
  background-color: var(--ion-color-primary);
  color: #ffffff !important;
}

.message-balloon.blue a {
  color: #ffffff !important;
}

.chat-info {
  margin-top: 5px;
}

.message-balloon .chat-info {
  margin-top: 13px;
}

.chat-image {
  background-color: var(--ion-color-light-shade);
  border-radius: 15px;
  max-width: 23rem;
}
.icon-image-sensitive {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  border-radius: 5px;
  fill: var(--ion-color-dark-tint);
}

.icon-image-failed {
  border-radius: 5px;
  fill: var(--ion-color-danger);
}

.img-is-hidden {
  opacity: 0;
}

.patient-image {
  border-radius: 5px;
}
</style>
