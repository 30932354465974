<template>
  <ion-page class="ion-page" main>
    <ion-header>
      <ion-grid v-if="showMessageFilters" class="active-conversation-subheader-container">
        <ion-row>
          <ion-col>
            <div>
              <PatientBanner
                :currentPatient="currentPatient"
                class="mobile patient-banner-mobile"
              />
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <!-- <div>
              <PatientBanner :currentPatient="currentPatient" class="mobile"/>
            </div> -->
            <ion-segment
              @ionChange="changeChannel($event)"
              :value="msgChannel"
              mode="ios"
              class="prime-channel-tabs-container"
            >
              <ion-segment-button
                layout="icon-start"
                value="sms"
                class="prime-channel-tab"
                mode="ios"
              >
                <span
                  :class="{
                    'needs-response-badge': needsResponseTab == 'sms'
                  }"
                ></span>
                <IconChatBubble v-if="!$isMobile" class="icon-small" />
                Text Message
              </ion-segment-button>
              <ion-segment-button layout="icon-start" value="zeus" mode="ios">
                <span
                  :class="{
                    'needs-response-badge': needsResponseTab == 'zeus'
                  }"
                ></span>
                <IconLock v-if="!$isMobile" class="icon-small" />
                Secure Chat
              </ion-segment-button>
              <div class="prime-channel-tab-separator"></div>
              <ion-segment-button value="all" mode="ios" class="prime-channel-tab-all">
                All
              </ion-segment-button>
            </ion-segment>
          </ion-col>
          <ion-col size="auto">
            <MessageOptions
              v-bind="{
                interactionId,
                msgChannel
              }"
              v-if="interactionId"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-header>

    <div
      id="message-panel-content"
      class="message-panel-content column-stretch-body"
      ref="messagePanel"
      v-chat-scroll="{
        always: false,
        smooth: false,
        scrollonremoved: true
      }"
    >
      <div class="messages-wrapper full-height">
        <div v-if="loading" class="text-center pad-ten loading-overlay center-center">
          <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
        </div>
        <ChatBubble
          v-for="message in filteredMessages"
          :key="message.id"
          v-bind="{
            message
          }"
        />
        <div class="text-muted full-width text-center" v-if="status == 'closed'">
          <div v-if="status == 'closed'">
            <small>{{ pageCopy.notifications.convoClosed }}</small>
          </div>
        </div>
        <div
          class="text-muted full-width text-center"
          v-if="initiator == 'system' && status == 'active'"
        >
          <div>
            <small>{{ pageCopy.notifications.systemMessage }}</small>
          </div>
        </div>
        <div class="text-muted full-width text-center" v-if="status == 'initiated'">
          <div>
            <small>{{ pageCopy.notifications.initiatedMessage }}</small>
          </div>
        </div>
        <div
          class="text-muted full-width full-height display-flex valign"
          v-if="filteredMessages.length == 0"
        >
          <div class="text-center full-width">
            There are no messages to display at this time
          </div>
        </div>
      </div>
    </div>
    <ion-footer class="column-stretch-footer prime-conv-message-entry-container" mode="ios">
      <ion-toolbar class="mobile-spcss">
        <MessageInput
          v-if="$can(I.SEND_MESSAGE) && showMessageInput"
          v-bind="{
            interactionId,
            beneficiaryId,
            accountId,
            copy: pageCopy.messageInput,
            msgChannel,
            activeConversation
          }"
        />
        <MessageControls
          v-bind="{
            messageTitle,
            accountId,
            interactionId,
            initiator,
            status,
            msgChannel,
            beneficiaryId,
            urgency,
            filteredMessages,
            programId
          }"
        />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import ChatBubble from "@/components/Chat/ChatBubble";
import MessageControls from "@/components/Chat/MessageControls";
import MessageInput from "@/components/Chat/MessageInput";
import { EventBus } from "@/services/Events";
import store from "@/store";

import MessageOptions from "@/components/Chat/MessageOptions";

import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconLock from "@/components/Global/Icons/IconLock";
import PatientBanner from "@/components/Settings/Patient/PatientBanner";
import { addIcons } from "ionicons";
import { call, lock } from "ionicons/icons";

addIcons({
  "md-lock": lock.md,
  "md-phone": call.md
});

export default {
  name: "MessagePanelMain",
  components: {
    ChatBubble,
    MessageInput,
    MessageControls,
    MessageOptions,
    IconChatBubble,
    IconLock,
    PatientBanner
  },
  props: {
    interactionId: Number,
    activeConversation: Boolean,
    currentPatient: Object,
    convoChannel: String,
    showMessageFilters: Boolean
  },
  data() {
    return {
      msgChannel: this.convoChannel,
      pageCopy: this.$languagePack.messagePanel,
      interactionEvent: document.config.events.interaction,
      status: "active",
      initiator: "conversation",
      urgency: undefined,
      pageNumber: 1,
      messagesPerPage: 20,
      loading: false,
      isConvoLocked: false,
      scrollHeight: 0,
      accountId: this.currentPatient.account.id,
      programId: null
    };
  },
  computed: {
    showMessageInput() {
      let shouldShow = true;
      if (
        this.currentPatient.enrollmentStatus != "approved" ||
        (this.messagesPayload.initiator &&
          this.messagesPayload.initiator === "system" &&
          this.messagesPayload.status === "active")
      ) {
        shouldShow = false;
      }
      return shouldShow;
    },
    beneficiaryId() {
      return this.currentPatient.beneficiary.id;
    },
    messages() {
      return store.getters["chatProfessional/messages"];
    },
    messagesPayload() {
      return store.getters["chatProfessional/messagesPayload"];
    },
    messageTitle() {
      return this.messagesPayload.subject;
    },
    scrollListening() {
      return store.getters["chatProfessional/scrollListening"];
    },
    subscription() {
      return { type: "interaction", data: this.interactionId };
    },
    currentInteractionId() {
      return store.getters["chatProfessional/currentInteractionId"];
    },
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    },
    filteredMessages() {
      const dedupedMessages = [...this.messages]
        .reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, [])
        .sort((a, b) => a.id - b.id);

      const sortedMessages = [...dedupedMessages].sort((a, b) => a.id - b.id);
      const filteredMessages = sortedMessages.filter(
        message => (message.channel === this.msgChannel || this.msgChannel == "all") && message.account_id === this.currentPatient.account.id
      );

      return filteredMessages;
    },
    showMessageControls() {
      return this.status == "active" || this.status == "initiated";
    },
    needsResponseTab() {
      let lastMsg = this.messages[this.messages.length - 1];
      if (lastMsg) {
        if (
          this.initiator === "conversation" &&
          (lastMsg.direction === "incoming" ||
            (lastMsg.direction === "outgoing" && lastMsg.source === "system"))
        ) {
          return lastMsg.channel;
        }
      }
      return "NORESPONSENEEDED";
    },
    atBeginningOfMessages() {
      return store.getters["chatProfessional/atBeginningOfMessages"];
    }
  },

  methods: {
    updateMessagePanelScrollBarLocation() {
      const messagePanel = this.$refs.messagePanel;
      if (messagePanel) {
        messagePanel.scrollTop = this.$refs.messagePanel.scrollHeight - this.scrollHeight;
      }
    },

    initializeMessageInfiniteScroll() {
      store.commit("chatProfessional/turnScrollListeningOff");
      const messagePanel = this.$refs.messagePanel;
      messagePanel && messagePanel.addEventListener("scroll", this.scrollListener);
      store.commit("chatProfessional/turnScrollListeningOn");
    },

    scrollListener(event) {
      if (this.scrollListening && event.target.scrollTop === 0 && !this.atBeginningOfMessages) {
        this.scrollHeight = event.target.scrollHeight;
        this.pageNumber++;
        this.getMessages();
      }
    },

    getMessages: async function() {
      if (this.interactionId) {
        this.loading = true;
        await store.dispatch("chatProfessional/currentInteractionId", { id: this.interactionId });
        await store.dispatch("chatProfessional/updateMessages", {
          id: this.currentInteractionId,
          pageNumber: this.pageNumber,
          messagesPerPage: this.messagesPerPage,
          patientId: this.beneficiaryId
        });
        this.loading = false;
        this.accountId =
          this.messagesPayload.messages && +this.messagesPayload.messages[0].account_id;
        this.status = this.messagesPayload.status;
        this.initiator = this.messagesPayload.initiator;
        this.urgency = this.messagesPayload.urgency;
        this.programId = this.messagesPayload.programId;
        this.isConvoLocked = this.messagesPayload.isConvoLocked;
      }
    },

    async changeChannel(event) {
      this.updateMessagePanelScrollBarLocation();
      this.msgChannel = event.target.value;
    }
  },

  async created() {
    await store.commit("chatProfessional/initializeMessages");
    await this.getMessages();
    await store.dispatch("websocket/subscribe", this.subscription);
    await this.initializeMessageInfiniteScroll();

    EventBus.$on("startSecureChat", () => {
      this.msgChannel = "zeus";
    });

    EventBus.$on("refresh-recent-images", img => {
      const msgIndex = this.filteredMessages.findIndex(obj => obj.id == img.messageId);
      const imgIndex = this.filteredMessages[msgIndex].attachments.findIndex(
        obj => obj.id == img.id
      );
      this.filteredMessages[msgIndex].attachments[imgIndex].hidden = !img.hidden;
    });
  },

  updated() {
    this.updateMessagePanelScrollBarLocation();
  },

  beforeDestroy: function() {
    store.dispatch("websocket/unsubscribe", this.subscription);
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }
};
</script>

<style scoped>
.active-conversation-subheader-container {
  padding: 0 20px 8px 20px;
}

@media (max-width: 767px) {
  .active-conversation-subheader-container {
    background: #fafafd;
  }
}

.prime-channel-tabs-container {
  background: var(--color-secondary-background);
  border-radius: 0 0 20px 20px;
  box-shadow: 0px -25px 0px 0px var(--color-secondary-background);
}
.prime-channel-tabs-container ion-segment-button {
  height: 36px;
  border-radius: 20px;
  margin: 5px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  fill: var(--ion-color-primary);
  transition: background 0.2s ease-in-out;
  --indicator-color-checked: transparent;
}
.prime-channel-tabs-container ion-segment-button svg {
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked {
  box-shadow: var(--prime-button-shadow);
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked svg {
  fill: #ffffff;
}

.prime-channel-tabs-container ion-segment-button .needs-response-badge {
  content: "";
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked .needs-response-badge {
  background: var(--ion-color-white);
}

.prime-channel-tabs-container ion-segment-button.prime-channel-tab-all {
  max-width: 60px;
}
.prime-channel-tabs-container .prime-channel-tab-separator {
  content: "";
  display: block;
  width: 2px;
  height: 30px;
  margin: 8px 5px;
  border-radius: 4px;
  background: var(--ion-color-primary);
  opacity: 10%;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

ion-card ion-card {
  padding: 0;
  border-radius: 6px !important;
  margin-left: -10px;
  margin-right: -10px;
}

ion-card ion-card:first-child {
  margin-top: 5px;
}

ion-card-content.prime-minimal-list {
  padding: 0 0 0 10px;
  margin-right: -10px;
}

.prime-patient-view ion-row.prime-full-height-row {
  height: calc(100vh - 165px) !important;
}

.prime-patient-view ion-row ion-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.prime-patient-view ion-col ion-card {
  margin-bottom: 0;
}

.prime-patient-view ion-col ion-card:last-child {
  margin-bottom: 0;
}

.prime-patient-view ion-card {
  flex-shrink: 0;
}

.prime-patient-view ion-card.scroll-shrink {
  flex-shrink: 1;
  flex-grow: 1;
}

.prime-patient-view ion-card-header {
  padding: 10px 10px 10px 20px;
}

.prime-internal-scoll-card ion-card-content {
  overflow: auto;
}

.prime-internal-scoll-card {
  display: flex;
  flex-direction: column;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.prime-patient-data-feed-card ion-card ion-card-title {
  font-size: 16px;
  font-weight: 600;
}

.prime-patient-data-feed-card ion-card ion-card-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium) !important;
  margin-top: 0;
}

.prime-patient-data-feed-card ion-card ion-card-header {
  padding-bottom: 0;
}

/* Right-Hand Accordion */
.prime-rh-accordion ion-card-header {
  display: flex;
  cursor: pointer;
}

.prime-rh-accordion ion-card-title {
  flex-grow: 1;
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls {
  font-size: 20px;
  margin-bottom: -5px;
  color: var(--ion-color-medium);
}

.prime-rh-accordion ion-card-header .ion-card-toggle-controls ion-icon.closed {
  margin-right: -20px;
}

/* messages column */

.messages-col-inner {
  padding: 0 40px;
}

.message-panel-content {
  padding-top: 8px;
}

@media (max-width: 767px) {
  .message-panel-content {
    background: #fafafd;
  }
}

.messages-wrapper {
  padding: 10px 20px;
}

.message-text-input {
  width: 100%;
  background-color: transparent;
  outline: none;
}

.message-active-badge {
  background-color: var(--ion-color-primary);
  height: 8px;
  width: 8px;
}

@media screen and (max-width: 500px) {
  .sc-ion-card-md-h {
    margin: 0px !important;
    margin-top: 10px !important;
  }
}

/* end messages column */

.prime-conv-message-entry-container {
  padding: 0px 0px 20px;
}
.footer-ios ion-toolbar:first-child {
  --border-width: 0;
}

.active-conversation-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 5px !important;
  z-index: 100;
}
.active-conversation-header .avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-grow: 0;
}
.active-conversation-header h5 {
  margin: 0;
  flex-grow: 1;
}
.active-conversation-header .active-conversation-righ-icons {
  flex-grow: 0;
  display: flex;
}
.active-conversation-header .active-conversation-righ-icons * {
  margin-left: 10px;
}

.active-conversation-title-group h6 {
  margin: 0 !important;
}

.active-conversation-options-group {
  width: 25px;
  text-align: right;
  margin-right: 20px;
  margin-left: 15px;
}

.patient-banner-mobile {
  text-align: left;
  z-index: 10000;
  overflow: visible;
}

@media (max-width: 767px) {
  .mobile-spcss {
    --background: #fafafd;
  }
}
</style>
